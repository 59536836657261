import React from "react"
import Header from "./header";
import Footer from "./footer"
import { Helmet } from "react-helmet";

const Wrapper = ({ slug, children, contactForm, customTitle, customDescription, customKeyword, customOgImage }) => {

    const defaultSEOConfig = {
        link: "https://www.appleseeds.my",
        // link: `${process.env.GATSBY_API_URL}`,
        
        title: "Web & App Design and Development Company in KL, Malaysia",
        ogTitle: "Appleseeds: Web & App Design and Development Company",

        description: "We are an experienced team of website & mobile app designers & developers who strives to create exceptionally beautiful & functional digital products.",
        keyword: "website, mobile app, system, content management system, design, development, designer, developer, kuala lumpur, company, team, agency, hotel, hospitality, news portal, corporate, lifestyle",
    }
    
    return (
        <div>
            <Helmet>
                <link rel="canonical" href={defaultSEOConfig.link + slug } />  

                {/* Facebook Meta Tags */}
                <meta property="og:url" content={defaultSEOConfig.link + slug } />
                <meta property="og:type" content="company" />
                <meta property="og:title"  content={customTitle || defaultSEOConfig.ogTitle} />
                <meta property="og:description" content={customDescription || defaultSEOConfig.description} />
                <meta property="og:image" content={`${defaultSEOConfig.link}${customOgImage || '/cover-home.jpg'}`} />

                {/* Twitter Meta Tags */}
                <meta property="twitter:url" content={defaultSEOConfig.link + slug } />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={customTitle || defaultSEOConfig.ogTitle} />
                <meta name="twitter:description" content={customDescription || defaultSEOConfig.description} />
                <meta name="twitter:image" content={`${defaultSEOConfig.link}${customOgImage || '/cover-home.jpg'}`} />

                <title>{customTitle || defaultSEOConfig.title}</title>
                <meta name="description" content={customDescription || defaultSEOConfig.description} />
                <meta name="keyword" content={customKeyword || defaultSEOConfig.keyword} />
            </Helmet>
            <Header />
            <main>
                {children}
            </main>
            <Footer contactForm={contactForm}/>
        </div>
    );
};

Wrapper.defaultProps = {
    contactForm: true
}

export default Wrapper;

