import React, { useState, useRef } from "react"
import {Link} from "gatsby"
import { Turn as Hamburger } from 'hamburger-react'

function Header() {

    const mobileMenu = useRef(null);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const onMobileMenuIconClick = () => setMobileMenuActive(!mobileMenuActive);

    const menu = [
        {
            id: "about",
            link: "/about/"
        },
        {
            id: "portfolio",
            link: "/portfolio/"
        },
        {
            id: "jobs",
            link: "/jobs/"
        },
        {
            id: "contact",
            link: "#contact"
        },
    ]

    return (
        <header className="fixed top-0 w-full bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg border-b border-brand-black border-opacity-10 transition-linear">
            <div className="container mx-auto">
                <div className="flex flex-row flex-wrap items-center -my-1 md:my-0 py-1 md:py-1">
                    <div className="flex-1">
                        <div className="logo">
                            <Link title="Appleseeds web and app design and development company in Kuala Lumpur, Malaysia" to="/">
                               
                            </Link>
                        </div>
                    </div>
                    {/* Mobile menu icon */}
                    <div className="flex-1 flex items-center justify-end md:hidden opacity-70">
                        <Hamburger className="" distance="lg" size={22} toggled={isOpen} toggle={setOpen} onToggle={onMobileMenuIconClick}/>
                    </div>
                    {/* Desktop menu */}
                    <nav className="flex-1 hidden md:block -mt-1">
                        <ul className="flex flex-row flex-wrap space-x-8 justify-end">
                            {menu.map((item) => {
                                return (
                                    <li class='hover:text-brand-gold transition-ease-in capitalize' key={item.id}>
                                        <Link to={`${item.link}`}>{item.id}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                    {/* Mobile menu */}
                    <ul ref={mobileMenu} class={`${mobileMenuActive ? 'scale-y-100 py-4 h-full opacity-100' : 'scale-y-0 h-0 opacity-0'} transition-linear transform origin-top md:hidden w-full flex-col space-y-4 border-t min-w-screen border-gray-400 border-opacity-50 -mx-4 w-screen`}>
                        {menu.map((item) => {
                            return (
                                <li class='px-4 hover:text-brand-gold transition-ease-in capitalize' key={item.id}>
                                    <Link to={`${item.link}`}>{item.id}</Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default Header;