import React, { useState } from "react"
// import '../styles/components/Footer.module.css'
import SectionTitle from "./sectionTitle";
// import { useForm, ValidationError } from '@formspree/react';


const Footer = ({footerChildren, contactForm}) => {

    const [formClick, setFormClick] = useState(false)

    return (
        <footer className="border-1 border-t border-brand-black border-opacity-10">
            {contactForm && 
                <section id="contact" className="section-contact">
                    <div className="container mx-auto">
                        <div className="flex flex-wrap">
                            {/* <div className="w-full lg:w-7/12">
                                <h2 className="text-4xl mb-3">Let&rsquo;s talk.</h2>
                                <p className="mb-5">We love meeting new people and talking about everything. <br className="d-none d-sm-block"/> Be it discussing new projects, exploring collaborations, sharing knowledge, or even just getting to know each other.</p>
                            </div> */}

                            {formClick === false ?
                                <>
                                <SectionTitle 
                                    wrapperClassName="md:w-3/4"
                                    alignCenter={false}
                                    title="Let&rsquo;s talk."
                                    minititle=""
                                    type="h2"
                                    invert={false}
                                    childrenClassName=""
                                    buttonText=""
                                    buttonType=""
                                    buttonLink=""
                                    >
                                    <p className="mb-5">
                                        We love meeting new people and talking about everything.&nbsp;
                                        <br className="hidden md:block"/> 
                                        Be it discussing new projects, exploring collaborations,&nbsp;
                                        <br className="hidden md:block"/> 
                                        sharing knowledge, or even just getting to know each other.</p>
                                </SectionTitle>
                                <div className="flex flex-col md:flex-row md:flex-nowrap md:space-x-4 lg:space-x-10 w-full mt-5">
                                    
                                    <div className="w-full md:w-2/3 lg:w-3/5 mb-8 md:mb-0">
                                        <h4 className="text-brand-gray text-sm mb-3 font-normal">Contact us</h4>
                                        <form action="https://formspree.io/hello@appleseeds.my" method="POST">
                                            <fieldset>
                                                <div className="flex flex-col lg:flex-row lg:flex-nowrap space-y-1 lg:space-y-0 lg:space-x-4">
                                                    <div className="w-full lg:w-1/2 flex flex-col">
                                                        <div>
                                                            <label htmlFor="name">Name</label>
                                                            <input type="text" name="name" id="name" required></input>
                                                            {/* <ValidationError prefix="name" field="name" errors={state.errors} /> */}
                                                        </div>
                                                        <div>
                                                            <label htmlFor="email">Email</label>
                                                            <input type="email" name="email" id="email" required></input>
                                                            {/* <ValidationError prefix="Email" field="email" errors={state.errors} /> */}
                                                        </div>
                                                        <div>
                                                            <label htmlFor="phone">Contact Number</label>
                                                            <input type="tel" pattern="[0-9]*" name="phone" id="phone" required></input>
                                                            {/* <ValidationError prefix="Phone" field="phone" errors={state.errors} /> */}
                                                        </div>
                                                    </div>
                                                    <div className="w-full lg:w-1/2">
                                                        <label htmlFor="message">Message</label>
                                                        <textarea name="message" id="message" required></textarea>
                                                        <button type="submit" 
                                                            className={`float-left md:float-right btn btn-filled`}>
                                                            Send
                                                        </button>
                                                    </div>
                                                    <input type="text" name="_gotcha" style={{display:'none'}} />
                                                    <input type="hidden" name="_next" value="https://www.appleseeds.my/thank-you/" />
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                    <div className="w-full md:w-1/3 lg:w-2/5">
                                        <h4 className="text-brand-gray text-sm mb-3 font-normal">Contact details</h4>
                                        <ul className="">
                                            <li className="email">
                                                <a href="mailto:hello@appleseeds.my">hello@appleseeds.my</a>
                                            </li>
                                            <li className="address">
                                                <address>
                                                    GMBB, GM9-3A, No.2, <br/>Jalan Robertson, Bukit Bintang, <br/>50150, Kuala Lumpur, Malaysia.<br/>
                                                    <a className="address-link" href="https://g.page/gmbbkl?share" target="_blank" rel="noreferrer">Direction <i className="icon-arrow-right leading-[0]"></i></a>
                                                </address>
                                            </li>
                                            <li className="social">
                                                <div className="links">
                                                    <a href="https://www.facebook.com/appleseeds.my" target="_blank" rel="noreferrer">Facebook</a> &middot;&nbsp;
                                                    <a href="https://www.linkedin.com/company/appleseeds-creativity/" target="_blank" rel="noreferrer">LinkedIn</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </>

                                :
                            
                                // This section is not working, submit button onClick function is commented as we are using old formspree page redirect method 
                                <SectionTitle
                                    wrapperClassName="lg:w-full mb-5"
                                    alignCenter={false}
                                    title={['Thank you for contacting us, ', <br className="hidden sm:block"/>, 'we will get back to you soon.']}
                                    minititle=""
                                    type="h3"
                                    invert={false}
                                    childrenClassName=""
                                    buttonText="Read our Portfolio"
                                    buttonType="btn-filled"
                                    buttonLink="/portfolio/"
                                >
                                </SectionTitle>
                            
                            }
                        </div>
                    </div>
                </section>
            }
            <section className="py-0">
                <div className="container mx-auto">
                    {footerChildren}
                </div>
            </section>
            <div className="container mx-auto">
                <p className="text-gray-400 text-2xs py-3">Copyright © 2022 Appleseedlers Creativity Sdn Bhd (1140056-P). All Rights Reserved.</p>
            </div>
        </footer>
    );
};
  
export default Footer;
  


