import React from "react";
import { Link } from 'gatsby';

const SectionTitle = ({ children, wrapperClassName, title, minititle, type, invert, childrenClassName, alignCenter, buttonLink, buttonText, buttonType }) => {
  return (
    <div className={`sectionTitle ${alignCenter ? 'mx-auto text-center' : ''} ${wrapperClassName}`}>
        {minititle && 
            <h4 className={`${invert ? 'text-white' : ''} font-normal mb-2`}>{minititle}</h4>
        }
        {type === "h1" &&
            <h1 className={`mb-6 ${invert ? 'text-white' : ''}`}>
                {title}
            </h1>
        }
        {type === "h2" &&
            <h2 className={`mb-5 ${invert ? 'text-white' : ''}`}>
                {title}
            </h2>
        }
        {type === "h3" &&
            <h3 className={`mb-4 ${invert ? 'text-white' : ''}`}>
                {title}
            </h3>
        }
        <div className={`${childrenClassName} ${invert ? 'text-white' : ''} ${alignCenter ? 'mx-auto text-center' : ''} description`}>
            {children}
        </div>
        { buttonText && 
            <Link className={`mt-8 inline-block btn ${ buttonType === "btn-filled" && "btn-filled"} ${ buttonType === "btn-light" && "btn-light"} `} to={buttonLink}>{buttonText}</Link>
        }
    </div>
  );
};

export default SectionTitle;
